<template>
  <div class="wrapper-def">
    <header className="top-menu">
      <div className="container-def">
        <div className="top-menu__wrap">
          <div className="top-menu__content">
            <div className="top-menu__left">
              <div className="logo-def top-menu__logo">
                <img src="img/logo.svg" alt="" className="logo-def__img">
              </div>
            </div>
            <div className="top-menu__right">
              <div className="socials">
                <div className="socials__el">
                  <a href="https://twitter.com/debox_network" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="31" height="31" viewBox="0 0 200 200" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <polygon points="69.9,59.8 127.3,134.9 136.1,134.9 79.3,59.8" fill="#717171" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M100,3.8C46.9,3.8,3.8,46.9,3.8,100c0,53.1,43.1,96.2,96.2,96.2s96.2-43.1,96.2-96.2C196.2,46.9,153.1,3.8,100,3.8z M122.9,144.4l-25-32.7l-28.6,32.7H53.4L90.5,102L51.4,50.8h32.8l22.6,29.9l26.2-29.9h15.9l-34.7,39.6l40.8,54H122.9z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://github.com/debox-network" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="29" height="29" viewBox="0 0 29 29" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.5 0C22.5084 0 29 6.6554 29 14.8667C29 21.4338 24.8501 27.0047 19.0922 28.9724C18.357 29.1188 18.096 28.6546 18.096 28.2587C18.096 27.7686 18.1134 26.1679 18.1134 24.1785C18.1134 22.7923 17.6494 21.8875 17.1289 21.4264C20.358 21.0581 23.751 19.8009 23.751 14.0908C23.751 12.4668 23.1884 11.1415 22.2575 10.1004C22.4083 9.72489 22.9056 8.21273 22.1154 6.16533C22.1154 6.16533 20.9003 5.76697 18.1322 7.68967C16.9737 7.36052 15.7325 7.19491 14.5 7.18911C13.2675 7.19491 12.0278 7.36052 10.8707 7.68967C8.0997 5.76697 6.8817 6.16533 6.8817 6.16533C6.09435 8.21273 6.5917 9.72489 6.74105 10.1004C5.8145 11.1415 5.24755 12.4668 5.24755 14.0908C5.24755 19.7864 8.6333 21.0629 11.8538 21.4385C11.4391 21.8097 11.0635 22.4645 10.933 23.4259C10.1065 23.8058 8.0069 24.4632 6.7135 22.1911C6.7135 22.1911 5.94645 20.7627 4.49065 20.6583C4.49065 20.6583 3.0769 20.6395 4.39205 21.5617C4.39205 21.5617 5.3418 22.0184 6.00155 23.7367C6.00155 23.7367 6.8527 26.3901 10.8866 25.4911C10.8939 26.7338 10.9069 27.9049 10.9069 28.2587C10.9069 28.6517 10.6401 29.1116 9.91655 28.9738C4.15425 27.0091 0 21.4352 0 14.8667C0 6.6554 6.4931 0 14.5 0Z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://discord.com/channels/1128766104723001344/1128766104723001347" target="_blank"
                    className="socials-el">
                    <svg class="socials-el__icon" width="33" height="26" viewBox="0 0 33 26" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M27.9313 2.13543C25.7959 1.14584 23.5215 0.416668 21.1257 0C20.8306 0.520836 20.4833 1.23264 20.2576 1.80556C17.7229 1.42362 15.2055 1.42362 12.7229 1.80556C12.4798 1.23264 12.1326 0.520836 11.8375 0C9.44164 0.416668 7.16732 1.14584 5.03189 2.13543C0.726321 8.62851 -0.436879 14.9654 0.13604 21.1981C3.00064 23.3335 5.76106 24.6182 8.48677 25.4689C9.16386 24.5488 9.75414 23.5592 10.275 22.5349C9.28538 22.1703 8.34788 21.7015 7.46246 21.1633C7.70552 20.9897 7.93121 20.7988 8.15691 20.6251C13.5909 23.1599 19.4764 23.1599 24.841 20.6251C25.0667 20.8161 25.2924 20.9897 25.5354 21.1633C24.65 21.7015 23.6952 22.1529 22.7229 22.5349C23.2438 23.5592 23.834 24.5488 24.5111 25.4689C27.2368 24.6182 30.0146 23.3335 32.8619 21.1981C33.5389 13.9584 31.6987 7.69101 27.966 2.13543H27.9313ZM11.0041 17.3786C9.37219 17.3786 8.03538 15.8682 8.03538 14.0105C8.03538 12.1529 9.33747 10.6425 11.0041 10.6425C12.6708 10.6425 13.9902 12.1529 13.9729 14.0105C13.9729 15.8508 12.6708 17.3786 11.0041 17.3786ZM21.959 17.3786C20.3271 17.3786 18.9903 15.8682 18.9903 14.0105C18.9903 12.1529 20.2924 10.6425 21.959 10.6425C23.6257 10.6425 24.9452 12.1529 24.9278 14.0105C24.9278 15.8508 23.6257 17.3786 21.959 17.3786Z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://debox.medium.com" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="30" height="30" viewBox="0 0 30 30" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_304_17301)">
                        <path
                          d="M21.1702 0C29.3595 0 30 0.6405 30 8.82975V21.1695C30 29.3588 29.3595 30 21.1702 30H8.82975C0.6405 30 0 29.3588 0 21.1702V8.82975C0 0.6405 0.6405 0 8.82975 0H21.1702ZM10.7137 20.5575C13.7827 20.5575 16.2712 18.069 16.2712 15C16.2712 11.931 13.7827 9.4425 10.7137 9.4425C7.64475 9.4425 5.15625 11.931 5.15625 15C5.15625 18.069 7.64475 20.5575 10.7137 20.5575ZM19.5773 20.289C21.0968 20.289 22.3275 17.9212 22.3275 15C22.3275 12.0788 21.096 9.711 19.5773 9.711C18.0585 9.711 16.827 12.0788 16.827 15C16.827 17.9205 18.0585 20.289 19.5773 20.289ZM23.85 19.7243C24.378 19.7243 24.8063 17.6093 24.8063 15C24.8063 12.3907 24.378 10.2758 23.85 10.2758C23.3212 10.2758 22.893 12.3907 22.893 15C22.893 17.6093 23.322 19.7243 23.85 19.7243Z"
                          fill="#717171" />
                      </g>
                      <defs>
                        <clipPath id="clip0_304_17301">
                          <rect width="30" height="30" rx="15" fill="#717171" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="sec-header">
      <div class="container-def">
        <div class="sec-header__wrap">
          <div class="sec-header__content">
            <div class="sec-header__left">
              <div class="sec-header__left-body">
                <h1 class="title-def title-def_main sec-header__title">A simple <span class="t-white">decentralized
                  </span>
                  <span>end-to-end</span> encrypted data storage & sharing platform
                </h1>
                <p class="text-info">Debox provides private storage and a secure way of transferring sensitive data,
                  guaranteeing users complete control over their digital assets.</p>
              </div>
            </div>
            <div class="sec-header__right">
              <div class="sec-header__right-body">
                <div v-if="false" class="sec-header__right-block">
                  <a download :href="this.fileDef" class="btn-def sec-header__btn">
                    <span>Download CLI</span>
                  </a>
                  <a href="https://docs.debox.network/getting-started/installation"
                    class="link-def sec-header__link plausible-event-name=Platforms">For other
                    platforms...</a>
                </div>
                <div class="sec-header__right-block">
                  <a href="https://docs.debox.network/getting-started/installation"
                    class="btn-def sec-header__btn plausible-event-name=Install"><span style="
    margin-left: 48px">Install</span></a>
                  <a href="https://docs.debox.network"
                    class="link-def sec-header__link_bold plausible-event-name=Docs">Documentation...</a>
                </div>
                <div v-if="false" class="sec-header__right-block">
                  <a download href="/docs/debox_white_paper.pdf" class="btn-def btn-def_2 sec-header__btn">
                    <span>Get White Paper</span>
                  </a>
                  <a href="https://docs.debox.network"
                    class="link-def link-def_2 sec-header__link_bold plausible-event-name=Docs">Documentation...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-use">
      <div class="container-def">
        <div class="sec-use__wrap">
          <div class="sec-use__content">
            <img v-bind:src="this.imgUse" :alt="this.os" class="sec-use__img">
            <img src="@/assets/img/dir/arrows.png" alt="" class="sec-use__arrows">
            <img src="@/assets/img/dir/arrows_mob.png" alt="" class="sec-use__arrowsmob">
            <div class="sec-use__body">
              <h2 class="title-def title-def_sec sec-use__title">Easy-to-use</h2>
              <p class="text-info text-info_2 sec-use__text">Debox provides an intuitive interface and seamless user
                experience via native access to files through your file explorer. Just mount it and you're ready to go!
              </p>
              <a v-if="false" href="/docs/easy-to-use.html"
                class="link-def link-def_3 plausible-event-name=Easy-Learn-More">learn
                more...</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-descrp">
      <div class="container-def">
        <div class="sec-descrp__wrap">
          <div class="sec-descrp__content">
            <div class="descrp">
              <div class="descrp__el">
                <div class="descrp-el">
                  <img src="@/assets/img/descrp/1.png" alt="" class="descrp-el__icon">
                  <div class="title-def descrp-el__title">Decentralized</div>
                  <p class="text-info descrp-el__text">Debox distributes your data across a network of independent nodes,
                    providing protection for your data against the risks associated with centralized storage.</p>
                  <a v-if="false" href="/docs/decentralized.html"
                    class="link-def link-def_3 plausible-event-name=Dec-Learn-More">learn
                    more...</a>
                </div>
              </div>
              <div class="descrp__el">
                <div class="descrp-el">
                  <img src="@/assets/img/descrp/2.png" alt="" class="descrp-el__icon">
                  <div class="title-def descrp-el__title">Private</div>
                  <p class="text-info descrp-el__text">Debox offers robust privacy features, including strict access
                    control, ensuring that your sensitive data can only be accessed by authorized parties. </p>
                  <a v-if="false" href="/docs/private.html"
                    class="link-def link-def_3 plausible-event-name=Priv-Learn-More">learn
                    more...</a>
                </div>
              </div>
              <div class="descrp__el">
                <div class="descrp-el">
                  <img src="@/assets/img/descrp/3.png" alt="" class="descrp-el__icon">
                  <div class="title-def descrp-el__title">Secure</div>
                  <p class="text-info descrp-el__text">Debox is built to be as secure as possible using proven end-to-end
                    encryption techniques, keeping your data safe from potential cyber threats.</p>
                  <a v-if="false" href="/docs/secure.html"
                    class="link-def link-def_3 plausible-event-name=Sec-Learn-More">learn
                    more...</a>
                </div>
              </div>
              <div class="descrp__el">
                <div class="descrp-el">
                  <img src="@/assets/img/descrp/4.png" alt="" class="descrp-el__icon">
                  <div class="title-def descrp-el__title">Fast</div>
                  <p class="text-info descrp-el__text">Debox has no bandwidth restrictions, allowing users to share large
                    amounts of files using torrent speeds vs. slow cloud storage transfer speeds.</p>
                  <a v-if="false" href="/docs/fast.html"
                    class="link-def link-def_3 plausible-event-name=Fast-Learn-More">learn
                    more...</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="sec-why">
      <div class="container-def">
        <div class="sec-why__wrap">
          <h2 class="title-def title-def_sec sec-why__title">Why Debox?</h2>
          <div class="sec-why__content">
            <div class="why">
              <div class="why__el" v-for="(item, index) in this.dataWhy" v-bind:key="item['title'] + index"
                v-show="index <= this.whyCount" v-bind:class="{ anim: index <= this.whyCount }">
                <div class="why-el">
                  <div class="why-el__title"><span>{{ item['title'] }}</span></div>
                  <ul class="why-el__descrp descrp-ul">
                    <li class="descrp-ul__el" v-for="(descrp, ind) in item['descrp']" v-bind:key="ind">{{ descrp }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <button class="btn-def sec-why__btn plausible-event-name=US-See-More" @click="this.showMoreWhy()"
              v-if="this.whyCount + 2 <= this.dataWhy.length">
              <span>See more...</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <footer class="sec-footer">
      <div class="container-def">
        <div class="sec-footer__wrap">
          <div class="sec-footer__content">
            <div class="sec-footer__left">
              <div class="sec-footer__text">“Debox Network" LLC</div>
            </div>
            <div class="sec-footer__right">
              <div className="socials">
                <div className="socials__el">
                  <a href="https://twitter.com/debox_network" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="31" height="31" viewBox="0 0 200 200" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <polygon points="69.9,59.8 127.3,134.9 136.1,134.9 79.3,59.8" fill="#717171" />
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M100,3.8C46.9,3.8,3.8,46.9,3.8,100c0,53.1,43.1,96.2,96.2,96.2s96.2-43.1,96.2-96.2C196.2,46.9,153.1,3.8,100,3.8z M122.9,144.4l-25-32.7l-28.6,32.7H53.4L90.5,102L51.4,50.8h32.8l22.6,29.9l26.2-29.9h15.9l-34.7,39.6l40.8,54H122.9z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://github.com/debox-network" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="29" height="29" viewBox="0 0 29 29" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.5 0C22.5084 0 29 6.6554 29 14.8667C29 21.4338 24.8501 27.0047 19.0922 28.9724C18.357 29.1188 18.096 28.6546 18.096 28.2587C18.096 27.7686 18.1134 26.1679 18.1134 24.1785C18.1134 22.7923 17.6494 21.8875 17.1289 21.4264C20.358 21.0581 23.751 19.8009 23.751 14.0908C23.751 12.4668 23.1884 11.1415 22.2575 10.1004C22.4083 9.72489 22.9056 8.21273 22.1154 6.16533C22.1154 6.16533 20.9003 5.76697 18.1322 7.68967C16.9737 7.36052 15.7325 7.19491 14.5 7.18911C13.2675 7.19491 12.0278 7.36052 10.8707 7.68967C8.0997 5.76697 6.8817 6.16533 6.8817 6.16533C6.09435 8.21273 6.5917 9.72489 6.74105 10.1004C5.8145 11.1415 5.24755 12.4668 5.24755 14.0908C5.24755 19.7864 8.6333 21.0629 11.8538 21.4385C11.4391 21.8097 11.0635 22.4645 10.933 23.4259C10.1065 23.8058 8.0069 24.4632 6.7135 22.1911C6.7135 22.1911 5.94645 20.7627 4.49065 20.6583C4.49065 20.6583 3.0769 20.6395 4.39205 21.5617C4.39205 21.5617 5.3418 22.0184 6.00155 23.7367C6.00155 23.7367 6.8527 26.3901 10.8866 25.4911C10.8939 26.7338 10.9069 27.9049 10.9069 28.2587C10.9069 28.6517 10.6401 29.1116 9.91655 28.9738C4.15425 27.0091 0 21.4352 0 14.8667C0 6.6554 6.4931 0 14.5 0Z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://discord.com/channels/1128766104723001344/1128766104723001347" target="_blank"
                    className="socials-el">
                    <svg class="socials-el__icon" width="33" height="26" viewBox="0 0 33 26" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M27.9313 2.13543C25.7959 1.14584 23.5215 0.416668 21.1257 0C20.8306 0.520836 20.4833 1.23264 20.2576 1.80556C17.7229 1.42362 15.2055 1.42362 12.7229 1.80556C12.4798 1.23264 12.1326 0.520836 11.8375 0C9.44164 0.416668 7.16732 1.14584 5.03189 2.13543C0.726321 8.62851 -0.436879 14.9654 0.13604 21.1981C3.00064 23.3335 5.76106 24.6182 8.48677 25.4689C9.16386 24.5488 9.75414 23.5592 10.275 22.5349C9.28538 22.1703 8.34788 21.7015 7.46246 21.1633C7.70552 20.9897 7.93121 20.7988 8.15691 20.6251C13.5909 23.1599 19.4764 23.1599 24.841 20.6251C25.0667 20.8161 25.2924 20.9897 25.5354 21.1633C24.65 21.7015 23.6952 22.1529 22.7229 22.5349C23.2438 23.5592 23.834 24.5488 24.5111 25.4689C27.2368 24.6182 30.0146 23.3335 32.8619 21.1981C33.5389 13.9584 31.6987 7.69101 27.966 2.13543H27.9313ZM11.0041 17.3786C9.37219 17.3786 8.03538 15.8682 8.03538 14.0105C8.03538 12.1529 9.33747 10.6425 11.0041 10.6425C12.6708 10.6425 13.9902 12.1529 13.9729 14.0105C13.9729 15.8508 12.6708 17.3786 11.0041 17.3786ZM21.959 17.3786C20.3271 17.3786 18.9903 15.8682 18.9903 14.0105C18.9903 12.1529 20.2924 10.6425 21.959 10.6425C23.6257 10.6425 24.9452 12.1529 24.9278 14.0105C24.9278 15.8508 23.6257 17.3786 21.959 17.3786Z"
                        fill="#717171" />
                    </svg>
                  </a>
                </div>
                <div className="socials__el">
                  <a href="https://debox.medium.com" target="_blank" className="socials-el">
                    <svg class="socials-el__icon" width="30" height="30" viewBox="0 0 30 30" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_304_17301)">
                        <path
                          d="M21.1702 0C29.3595 0 30 0.6405 30 8.82975V21.1695C30 29.3588 29.3595 30 21.1702 30H8.82975C0.6405 30 0 29.3588 0 21.1702V8.82975C0 0.6405 0.6405 0 8.82975 0H21.1702ZM10.7137 20.5575C13.7827 20.5575 16.2712 18.069 16.2712 15C16.2712 11.931 13.7827 9.4425 10.7137 9.4425C7.64475 9.4425 5.15625 11.931 5.15625 15C5.15625 18.069 7.64475 20.5575 10.7137 20.5575ZM19.5773 20.289C21.0968 20.289 22.3275 17.9212 22.3275 15C22.3275 12.0788 21.096 9.711 19.5773 9.711C18.0585 9.711 16.827 12.0788 16.827 15C16.827 17.9205 18.0585 20.289 19.5773 20.289ZM23.85 19.7243C24.378 19.7243 24.8063 17.6093 24.8063 15C24.8063 12.3907 24.378 10.2758 23.85 10.2758C23.3212 10.2758 22.893 12.3907 22.893 15C22.893 17.6093 23.322 19.7243 23.85 19.7243Z"
                          fill="#717171" />
                      </g>
                      <defs>
                        <clipPath id="clip0_304_17301">
                          <rect width="30" height="30" rx="15" fill="#717171" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import imageWin from "@/assets/img/dir/win.jpg"
import imageMac from "@/assets/img/dir/mac.jpg"
import imageLinux from "@/assets/img/dir/ubuntu.jpg"

export default {
  name: 'App',
  data() {
    return {
      os: '',
      imgUse: '',
      imgUseWin: imageWin,
      imgUseMac: imageMac,
      imgUseLinux: imageLinux,
      fileDef: '',
      fileWin: '/downloads/debox_v0.1.0_windows-amd64.zip',
      fileMac: '/downloads/debox_v0.1.0_darwin-amd64.tar.gz',
      fileLinux: '/downloads/debox_v0.1.0_linux-amd64.tar.gz',
      whyCount: 2,
      dataWhy: [
        {
          title: "Don't trust any commercial storage provider?",
          descrp: [
            "No centralized control over data accessability & availability",
            "No user data or metadata (not even anonymized) is stored by Debox",
          ],
        },
        {
          title: "Tired of slow storage provider file transfer rates?",
          descrp: [
            "Share files using torrent speeds vs. cloud storage upload/download speeds",
            "No bandwidth restrictions / throttling",
          ],
        },
        {
          title: "Storage provider blocked by regional ISP?",
          descrp: [
            "Service is independent of Debox web domain",
            "P2P networking circumvents any potential censorship",
          ],
        },
        {
          title: "Worried about data leaks?",
          descrp: [
            "All data is end-to-end encrypted, using latest encryption protocols (Signal, etc.)",
            "Keys are stored ONLY on user nodes",
          ],
        },
        {
          title: "Worried that your digital assets can be seized?",
          descrp: [
            "Unshared stored data is accessible ONLY to user",
            "Full control over which data elements to share and with whom",
          ],
        },
        {
          title: "Having a hard time sharing very large files?",
          descrp: [
            "Easily store very large files (up 32 GB)",
            "Easily share extremely large files (limited only by OS)",
          ],
        },
        {
          title: "Paying too much for storage?",
          descrp: [
            "Pay only for the amount you store",
            "Freely transfer anywhere when hosting your own data",
          ],
        },
        {
          title: "Don't know how to easily share large photo library with friends?",
          descrp: [
            "Native access to files via file explorer on ALL platforms",
            "Simple GUI to manage group access",
          ],
        },
        {
          title: "Need a data storage and sharing solution for your company's team?",
          descrp: [
            "Fine-tune roll-based access for data sharing within teams",
            "Streamlined search over data with elegant UI",
          ],
        },
      ],
    };
  },
  mounted() {
    this.os = this.detectOS();
    this.setImgUse();
    if (window.screen.width <= 990) {
      this.whyCount = 1;
    }
    this.menu();
    this.stats();
  },
  methods: {
    setImgUse() {
      if (this.detectOS() === 'windows') {
        this.imgUse = this.imgUseWin;
        this.fileDef = this.fileWin;
      } else if (this.detectOS() === 'mac') {
        this.imgUse = this.imgUseMac;
        this.fileDef = this.fileMac;
      } else if (this.detectOS() === 'linux') {
        this.imgUse = this.imgUseLinux;
        this.fileDef = this.fileLinux;
      }
    },
    detectOS() {
      const platform = navigator.platform;
      if (platform.indexOf('Win') !== -1) return 'windows';
      if (platform.indexOf('Mac') !== -1) return 'mac';
      if (platform.indexOf('Linux') !== -1) return 'linux';
      return 'windows';
    },
    showMoreWhy() {
      this.whyCount += 2;
    },
    menu() {
      let $menu = document.querySelector('.top-menu');
      document.addEventListener("scroll", () => {
        if (window.pageYOffset > 0) {
          $menu.classList.add('fixed');
        } else {
          $menu.classList.remove('fixed');
        }
      });
    },
    stats() {
      const script = document.createElement("script");
      script.setAttribute("defer", "defer");
      script.setAttribute("data-domain", "debox.network");
      script.setAttribute("src", "https://stats.debox.dev/js/script.file-downloads.outbound-links.tagged-events.js");
      document.head.appendChild(script);
    },
  },
}
</script>
<style lang="scss">
@import './assets/sass/style.scss';
</style>
